export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36')
];

export const server_loads = [];

export const dictionary = {
		"/": [7],
		"/blog": [23],
		"/blog/[slug]": [24],
		"/(marketing)/bootcamps": [8],
		"/contact": [25,[3]],
		"/(marketing)/customer-education": [9],
		"/(redirects)/demo": [~11],
		"/(redirects)/devanddesign": [~12],
		"/(redirects)/discord": [~13],
		"/(redirects)/docs": [14,[2]],
		"/(marketing)/employee-training": [10],
		"/(redirects)/github": [~15],
		"/(redirects)/learn": [~16],
		"/(redirects)/new": [~17],
		"/oss-friends": [26],
		"/(redirects)/ph": [~18],
		"/pricing": [27,[4]],
		"/(redirects)/privacy": [~19],
		"/roadmap": [28],
		"/(redirects)/signup": [~20],
		"/teach": [29,[5]],
		"/teach/register": [~30,[5]],
		"/tools": [31,[6]],
		"/tools/name-picker": [32,[6]],
		"/tools/pomodoro": [33,[6]],
		"/tools/progress": [34,[6]],
		"/tools/stopwatch": [35,[6]],
		"/tools/tic-tac-toe": [36,[6]],
		"/(redirects)/tos": [~21],
		"/(redirects)/waec": [~22]
	};

export const hooks = {
	handleError: (({ error }) => { console.error(error) }),
};

export { default as root } from '../root.svelte';